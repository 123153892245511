.testimonialsContainer{
    margin: 0 auto;
    width: 90vw;
    margin-top: 80px;
    margin-bottom: 100px;

    .title {
      margin: 120px 0px 10px 0px;
    }

    .testimonialsSlider{
        height: 500px;
        width: 100%;
        .testimonialsSliderSlide{
            background-color: var(--orange);
            aspect-ratio: 3/1;
            border-radius: 20px;
            height: 350px;
            opacity: .4 !important;
            margin: auto 0;  
            min-width: 300px;
            max-width: 600px;
            flex: 0 0 auto;
            width: 100%;
            will-change: height;
            transition: transform .3s ease, opacity .2s ease, height .3s ease;
            .testipmonialsSliderContent{
                margin: 30px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                height: 100%;
                gap: 5px;
                .starsContainer{
                    display: flex;
                    align-items: center;
                    justify-content: left;
                    gap: 10px;
                    width: 100%;
                    height: 40px;
                    position: relative;
                    margin-bottom: 60px;
                    margin-top: auto;
                    .star{
                        display: block;
                        width: 40px;
                        height: 40px;
                        i{
                            color: var(--dark-blue);
                            width: 100%;
                            height: 100%;
                        }
                    }

                }
            }
        }
        .swiperSlidePrev{
            opacity: .4 !important;
            margin: auto 0;  
            z-index: -1;
        }
        .swiperSlideNext{
            opacity: .4 !important;
            margin: auto 0;  
            z-index: -1;
        }
        .swiperSlideActive{
            margin: auto 0;
            opacity: 1 !important;
            transform: scale(1.2);
        }
    }
}

@media (max-width: 1200px) {
    .testimonialsContainer {
      width: 95vw;
      .testimonialsSlider {
        .testimonialsSliderSlide {
          height: 50% !important;
        }
        .swiperSlideActive {
          height: 75% !important;
        }
      }
    }
  }
  
  @media (max-width: 992px) {
    .testimonialsContainer {
      width: 100vw;
      .testimonialsSlider {
        .testimonialsSliderSlide {
          height: 45% !important;
        }
        .swiperSlideActive {
          height: 70% !important;
          transform: scale(1.05);
        }
      }
    }
  }
  
  @media (max-width: 768px) {
    .testimonialsContainer {
      width: 95vw;
      margin-top: 50px;
      .testimonialsSlider {
        .testimonialsSliderSlide {
          height: 40% !important;
        }
        .swiperSlideActive {
          height: 65% !important;
          transform: none;
        }
      }
    }
  }
  
  @media (max-width: 576px) {
    .testimonialsContainer {
      width: 90vw;
      margin-top: 40px;
      .testimonialsSlider {
        .testimonialsSliderSlide {
          height: 35% !important;
        }
        .swiperSlideActive {
          height: 60% !important;
        }
      }
    }
  }



