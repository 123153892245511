.homeContainer {
    width: 100%;
    height: 95vh;
    overflow: hidden;
    position: relative; 
    z-index: 1;

    .videoBlock {
        width: 100%;
        height: 100vh;
        position: absolute;
        overflow: hidden;

        video {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%; 
            object-fit: cover;
            pointer-events: none;
            user-select: none;
            z-index: -1; 
        }
    }

    .gradienBg {
        width: 100%;
        height: 100%;
        position: absolute;
        background: linear-gradient(to bottom, 
              #00000099 0%, 
              #00000090 70%, 
              #00000099 100%);
    }

    .homeContent {
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1.5rem;
        transform: translateY(5%);
    }

    .titleBlock {
        max-width: 58%;
        position: relative;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        color: #fff;
        margin-left: 12vw;
        gap: 18px;

        h1 {
            text-transform: uppercase;
            font-size: clamp(36px, 4vw, 60px);
            margin: 0;
        }

        span {
            color: var(--orange);
        }

        p {
            font-size: clamp(14px, 2vw, 20px);
            margin: 0;
            margin-bottom: 16px;
            max-width: 78%;
        }

        a {
            background-color: var(--orange);
            width: 280px;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50px;
            color: var(--dark-blue);
            font-size: 16px;
            font-weight: 700;
            gap: 20px;
            text-transform: uppercase;
        }
    }

    @media (max-width: 1200px) {
        .titleBlock {
            margin-left: 8vw;
            max-width: 70%;

            h1 {
                font-size: clamp(36px, 5vw, 56px);
            }

            a {
                width: 240px;
                height: 55px;
                font-size: 15px;
            }
        }
    }

    @media (max-width: 992px) {
        .homeContent {
            justify-content: center;
            text-align: center;
        }
        .titleBlock {
            max-width: 80%;
            margin: 0 24px;
            align-items: center;

            h1 {
                font-size: clamp(34px, 10vw, 42px);
            }

            p {
                max-width: 90%;
            }

            a {
                width: 220px;
                height: 50px;
                font-size: 14px;
            }
        }
    }

    @media (max-width: 576px) {
        height: 100vh;
        .videoBlock{
            height: 100%;
            video{
                height: 100%;
                width: auto;
            }
        }
        .titleBlock {
            max-width: 100%;
            margin: 16px;

            h1 {
                font-size: clamp(16px, 8vw, 34px);
            }

            p { 
                max-width: 90%;
            }

            a {
                width: 210px;
                height: 40px;
                font-size: 12px;
            }
        }
    }
}
