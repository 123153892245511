.galleryContainer {
    margin: 40px auto;
    padding: 0 80px;
    scroll-padding-top: 140px;

   

    .gallerySlide {
        flex: 0 0 auto;
        width: auto;
        min-width: 200px;
    }

    .galleryImage {
        border-radius: 16px;
        max-width: 400px;
        width: 100%;
        height: 100%;
        object-fit: cover;
        
    }
}

@media (max-width: 1200px) {
    .galleryContainer {
        padding: 0 40px;
    }
}

@media (max-width: 768px) {
    .galleryContainer {
        padding: 0 20px;

        h2 {
            font-size: clamp(34px, 10vw, 54px);
        }
    }
}

@media (max-width: 480px) {
    .galleryContainer {
        h2 {
            text-align: center;
        }
    }
}