.servicesContainer {
    color: #ffffff;
    padding: 0 80px;
    margin: 0 0 80px;

    .servicesSlide {
        width: 80%;
        height: 650px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 auto;
        background-color: var(--blue);
        gap: 60px;
        overflow: hidden;
        border-radius: 20px;

        > * {
            flex: 1;
        }

        .servicesSlideImage {
            height: 100%;
            margin-left: 0;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;

            > div {
                height: 100%;
                width: 100%;
            }

            img {
                height: 100%;
                object-fit: cover;
                border-radius: 20px 0px 0px 20px;
            }
        }

        .servicesSlideInfo {
            height: 100%;
            width: 45%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: left;
            gap: 0;

            h2 {
                font-size: 36px;
                font-weight: 800;
                text-align: left;
                margin-bottom: 0;
                width: 75%;
            }

            p {
                max-width: 90%;
                font-size: 18px;
                font-weight: 400;
                text-align: left;
                color: #ffffff;

                ul {
                    display: flex;
                    flex-wrap: wrap;
                    list-style-type: none;
                    gap: 10px;
                    padding: 0;

                    li {
                        padding: 10px 20px;
                        border: 1px solid #ffffff60;
                        border-radius: 100px;
                    }
                }
            }

            a {
                background-color: var(--orange);
                width: 210px;
                height: 50px;
                display: flex;
                border-radius: 50px;
                color: var(--dark-blue);
                font-weight: 800;
                align-items: center;
                justify-content: center;
                margin-bottom: 30px;
                margin-top: auto;
                gap: 10px;

                svg {
                    max-width: 25px;
                }

                svg path {
                    fill: var(--dark-blue);
                }
            }
        }
    }

    @media (max-width: 1200px) {
        padding: 0 40px;

        .servicesSlide {
            // height: 550px;
            gap: 40px;

            .servicesSlideInfo {
                h2 {
                    font-size: 30px;
                }

                p {
                    font-size: 16px;
                }
            }
        }
    }

    @media (max-width: 992px) {
        padding: 0 20px;

        .servicesSlide {
            flex-direction: column;
            height: auto;
            gap: 30px;
            .servicesSlideImage{
                max-height: 320px;
            }
            .servicesSlideImage,
            .servicesSlideInfo {
                width: 100%;
            }

            .servicesSlideInfo {
                align-items: center;
                text-align: center;
                h2 {
                    width: 80%;
                    font-size: 28px;
                }

                p {
                    max-width: 80%;
                }

                a {
                    margin-top: 20px;
                }
            }
        }
    }

    @media (max-width: 768px) {
        .servicesSlide {
            gap: 20px;

            .servicesSlideInfo {
                h2 {
                    font-size: 24px;
                }

                p {
                    font-size: 14px;
                }

                a {
                    width: 180px;
                    height: 40px;
                }
            }
        }
    }

    @media (max-width: 576px) {
        padding: 0 10px;
        
        .servicesSlide {
            gap: 15px;

            .servicesSlideInfo {
                h2 {
                    font-size: 20px;
                }

                p {
                    font-size: 12px;
                }

                a {
                    width: 160px;
                    height: 35px;
                    font-size: 14px;

                    svg {
                        max-width: 20px;
                    }
                }
            }
        }
    }
}


