.feedbackContainer{
    padding: 0 80px;

    p {
        font-size: clamp(14px, 4vw, 24px);
        opacity: 0.9;
        max-width: 1000px;
        color: #fff;
    }

    span {
        opacity: 0.7;
    }

    .feedbackInfo {
        display: flex;
        flex-wrap: wrap;
        gap: clamp(10px, 4vw, 1.5rem);
        max-width: 80%;
        
        .infoBlock {
            padding: 24px 24px;
            border-radius: 8px;
            display: flex;
            align-items: center;
            opacity: .80;
            gap: 1rem;
            flex: 1 1 300px;
            
            p {
                font-weight: 600;
                opacity: 1;
                font-size: 18px;
                margin: 0;
            }

            img {
                width: 38px;
                height: 38px;
            }
        }
        
        .greenBlock {
            --color: #ffffffc3;
            border: 3px solid var(--color);

            g {
                fill: rgb(10, 206, 10);
            }
        }

        .blueBlock {
            --color: #ffffffc3;
            border: 3px solid var(--color);

            g {
                fill: var(--color);
            }
        }
    }

    .feedbackForms{
        display: flex;
        width: 100%;
        flex-direction: column;
        h3{
            margin-bottom: 0;
            color: var(--white);
            font-size: 66px;
            font-weight: bold;
            letter-spacing: -4px;
        }
        .feedbackFormsTabs{
            display: flex;
            align-items: flex-end;
            gap: 30px;
            .feedbackTabs{
                width: 50%;
                margin-bottom: 20px;
                border-bottom: 1px solid var(--orange);
                button{
                    background-color: transparent;
                    border: 1px solid var(--orange);
                    color: var(--orange);
                    padding: 10px 25px;
                    border-radius: 10px 10px 0 0;
                    transition: .3s;
                    font-size: 20px;
                    font-weight: bolder;
                }
                button.active{
                    background-color: var(--orange);
                    color: var(--dark-blue);
                }
            }
        }
        .feedbackFormsForm{
            display: flex;
            max-width: 100%;
            .firstForm{
                flex-direction: column;
            }
            form{
                display: flex;
                width: 100%;
                flex-wrap: wrap;
                gap: 20px;
                padding: 40px 0px;
                color: var(--white);
                .contextBox{
                    box-sizing: border-box;
                    padding: 40px;
                    width: 100%;
                    border-radius: 20px;
                    border: 1px solid #F8A21930;
                    display: flex;
                    justify-content: space-between;
                    .blockName{
                        position: absolute;
                        margin-top: -50px;
                        background-color: var(--dark-blue);
                        padding: 0 20px;
                        text-align: center;
                    }
                    .contentBoxBlock{
                        width: 45%;
                        display: flex;
                        flex-direction: column;
                        gap: 20px;
                        h4{
                            display: flex;
                            gap: 10px;
                            align-items: center;
                            margin: 0;
                        }
                        .blockSwitch{
                            display: flex;
                            align-items: center;
                            justify-content: left;
                            padding: 0;
                            button{
                                width: 100px;
                                margin-left: 0;
                                margin-right: 20px;
                            }
                        }
                        .blockCheckboxes{
                            height: 40px;
                            width: 100%;
                            display: flex;
                            gap: 40px;
                        }
                    }
                }
                .contextBoxContacts{
                    display: flex;
                    text-align: center;
                    flex-direction: column;
                    width: 45%;
                    gap: 20px;
                    margin: auto 0;
                    h2{
                        font-size: 42px;
                        width: 85%;
                        margin: 0 auto;
                    }
                }
                .buttonSubmit{
                    background-color: transparent;
                    color: var(--orange);
                    border: 1px solid var(--orange);
                    border-radius: 10px;
                    width: 300px;
                    height: 40px;
                    transition: .3s;
                    margin: 0 auto;
                    &:hover{
                        background-color: var(--orange);
                        color: var(--dark-blue);
                    }
                }
            }
        }
    }
}

@media (max-width: 1200px) {
    .feedbackContainer {
        padding: 0 40px;

        .feedbackForms {
            h3 {
                font-size: 48px; 
            }

            .feedbackFormsTabs {
                flex-direction: column; 
                gap: 10px;

                .feedbackTabs {
                    width: 100%; 
                }
            }

            .feedbackFormsForm {
                form {
                    padding: 20px; 
                }

                .formFinishing{
                    width: 90%;
                }
                .contextBox {
                    padding: 20px; 
                    flex-direction: column; 
                    gap: 10px;
                    width: 90%;
                    .contentBoxBlock{
                        width: 100% !important;
                    }
                }

                .contextBoxContacts {
                    width: 100% !important;
                    margin: 0 auto !important;
                    h2 {
                        font-size: 28px; 
                        text-align: center;
                    }
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .feedbackContainer {

        .feedbackInfo {
            max-width: 100%;

            .infoBlock {
                p {
                    font-size: clamp(14px, 3vw, 18px);
                }

                img {
                    width: 30px;
                    height: 30px;
                }
            }
        }

        
        padding: 0 20px;
        .feedbackForms {
            h3 {
                font-size: 48px; 
            }

            .feedbackFormsTabs {
                flex-direction: column; 
                gap: 10px;

                .feedbackTabs {
                    width: 100%; 
                }
            }

            .feedbackFormsForm {
                form {
                    padding: 20px; 
                }

                .formFinishing{
                    width: 90%;
                }
                .contextBox {
                    padding: 20px; 
                    flex-direction: column; 
                    gap: 10px;
                    width: 90%;
                    .contentBoxBlock{
                        width: 100% !important;
                    }
            
                }

                .contextBoxContacts {
                    width: 100% !important;
                    margin: 0 auto !important;
                    h2 {
                        font-size: 28px !important; 
                        text-align: center;
                    }
                }
            }
        }
    }
}

@media (max-width: 480px) {
    .feedbackContainer {
        padding: 0 10px;

        .feedbackInfo {
            .infoBlock {
                padding: 16px;
            }
        }

        .feedbackForms {
            h3 {
                font-size: 36px; 
            }

            .feedbackFormsForm {
                form {
                    padding: 10px; 
                    h4{
                        flex-direction: column;
                    }
                    .blockCheckboxes{
                        flex-direction: column;
                        gap: 5px !important;
                        margin: 5px !important;
                    }
                }

                .contextBox {
                    padding: 10px;
                    gap: 5px;
                }

                .contextBoxContacts {
                    h2 {
                        font-size: 18px !important; 
                    }
                }

                .buttonSubmit {
                    width: 100% !important;
                    height: 50px;
                }
            }
        }
    }
}

