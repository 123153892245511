.main{
    width: 100%;
    background-color: var(--dark-blue);
    overflow: hidden;
}

.container{
    max-width: 1920px;
    margin: 0 auto;
    width: 100%;
    overflow: hidden;
    .blockTitle{
        font-weight: 800;
        text-transform: uppercase;
        font-size: 80px;
        line-height: 100%;
        text-align: center;
        letter-spacing: -1px;
        color: #ffffff90;
        margin-bottom: 50px;
        margin-top: 80px;
    }
}

@media (max-width: 1200px) {
    .container .blockTitle {
        font-size: 70px;
    }
}

@media (max-width: 768px) {
    .container .blockTitle {
        font-size: 50px;
    }
}

@media (max-width: 480px) {
    .container .blockTitle {
        font-size: 30px;
    }
    .main{
        width: 100%;
        background-color: var(--dark-blue);
    }
}


