.header {
    position: fixed;
    top: 0;
    width: 100%;
    margin: 0;
    border-radius: 0 0 20px 20px;
    height: 150px;
    transition: 0.2s;
    display: flex;
    align-items: center;
    z-index: 1000;
  
    .headerNav {
      width: 100%;
      margin: 0 90px;
  
      .headerList {
        list-style-type: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
  
        .headerLogo,
        .headerLogoScrolled {
          transition: opacity 0.5s ease-in-out;
          height: 100%;
          width: 150px;
        }
  
        .headerLogo {
          opacity: 1;
        }
  
        .headerLogoScrolled {
          opacity: 0;
        }
  
        .headerLogoScrolled {
          opacity: 1;
        }
  
        .headerLinks {
          display: flex;
          align-items: center;
          gap: 10px;
  
          a {
            color: var(--orange);
            font-size: 16px;
            font-weight: 700;
            padding-inline: 1rem;
            text-align: center;
          }
          a:last-child{
            color: var(--dark-blue);
            background-color: var(--orange);
            padding: 15px 20px;
            border-radius: 50px;
          }
        }

        .phoneMenu{
          display: flex;
          align-items: center;
          gap: 15px;
          margin-right: 20px;
          margin-left: auto;
          color: var(--dark-blue);
          img{
            width: 20px;
          }
        }
  
        .hamburgerMenu {
          display: none;
          align-items: center;
          justify-content: center;
          button {
            background: none;
            border: none;
            cursor: pointer;
            color: var(--dark-blue);
            width: 30px;
            height: 30px;
            line-height: 100%;
          }
        }
      }
    }
  }
  
  .scroll {
    height: 80px;
    background-color: var(--orange);
  
    .headerNav {
      .headerList {
        .headerLinks {
          width: 0;
          overflow: hidden;
          position: absolute;
          right: 70px;
        }
  
        .hamburgerMenu {
          display: flex;
        }
      }
    }
  }

  .sidebar{
    position: absolute;
    height: 100vh;
    margin: 0;
    width: 35%;
    left: auto;
    right: 0;
    top: 0;
    border-bottom-left-radius: 120px;
    background-color: var(--orange);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    transform: translateX(100%);
    transition: .3s;
    .closeBtn{
      position: absolute;
      right: 40px;
      top: 40px;
      border: none;
      background: none;
      border-radius: 100px;
      width: 40px;
      height: 40px;
      i{
        font-size: 28px;
        color: var(--dark-blue);
      }
    }
    .sidebarContainer{
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-top: 70px;
      gap: 20px;
      a{
        font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        font-size: 36px;
        letter-spacing: -5%;
        color: var(--dark-blue);
      }
    }
  }

.sidebar.active{
  transform: translateX(0);
}
  
@media (max-width: 1200px) {
  .headerNav {
      margin: 0 20px !important;

      .headerList .headerLinks a {
          font-size: 12px;
      }
      .headerLogo,
      .headerLogoScrolled {
        width: 110px !important;
      }
  }
  .headerLinks {
    width: 85% !important;
  }

  .sidebar {
      width: 40%;
      .sidebarContainer{
        a{
          font-size: 22px;
        }
      }
  }
  .scroll {
    .headerNav {
      .headerList {
        .headerLinks {
          width: 0;
          overflow: hidden;
          position: absolute;
          transform: translateX(200%);
          right: 70px;
        }
  
        .hamburgerMenu {
          display: flex;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .header {
      height: 90px;
  }

  .headerNav {
      margin: 0 20px;

      .headerList.headerList {
          justify-content: flex-start;

          .headerLinks {
              width: 100%;
              gap: 5px;

              a {
                  font-size: 14px;
                  width: auto;
              }

              a:last-child {
                  padding: 10px 15px;
              }
          }

          .phoneMenu.phoneMenu {
            gap: 6px;
            margin-right: 12px;
            flex: 1;
            justify-content: flex-end;
            text-wrap: nowrap;
          }

          .hamburgerMenu {
              display: flex;
              justify-content: flex-end;
          }
      }
  }

  .sidebar {
      width: 70%;
  }
}

@media (max-width: 480px) {
  .header {
      height: 100px;
  }

  .headerNav {
      margin: 0 10px;

      .headerList {
          flex-wrap: wrap;

          > * {
            flex: 1 1 80px;
          }

          .hamburgerMenu {
            flex: 0;
          }

          .phoneMenu.phoneMenu {
            font-size: 14px;


            svg {
              width: 16px;
            }
          }
          .headerLinks {
              gap: 5px;

              a {
                  font-size: 12px;
              }

              a:last-child {
                  padding: 8px 12px;
                  font-size: 14px;
              }
          }
      }
  }

  .sidebar {
      width: 100%;
  }
}

